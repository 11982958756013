<!-- <app-loader></app-loader> -->
<app-layout>
  <mat-sidenav-container fullscreen>
    <mat-sidenav #sidenav mode="over" role="navigation">
      <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-content-layout>
        <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
        <div class="content-wrapper">
          <div class="main-conent">
            <router-outlet></router-outlet>
          </div>
          <app-footer></app-footer>
        </div>
      </app-content-layout>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout>