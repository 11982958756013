<div class="login-form-flex">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Register</mat-card-title>
    </mat-card-header>
    <form [formGroup]="loginForm">
      <mat-card-content>
        <mat-form-field>
          <input matInput placeholder="Username" formControlName="username">
        </mat-form-field>
        <br>
        <mat-form-field>
          <input matInput placeholder="Email">
        </mat-form-field>
        <br>
        <mat-form-field>
          <input matInput placeholder="Password">
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Choose a role...</mat-label>
          <mat-select>
            <mat-option [value]="roles" *ngFor="let roles of Roles">{{roles}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </mat-card-content>
      <mat-card-actions>
        <div class="button-flex-container">
          <button mat-raised-button color="primary" class="btn-block">Register</button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card>
</div>