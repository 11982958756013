<div *ngFor="let linkSection of _linkSections" class="toc-container">
    <div class="toc-heading">{{linkSection.name}}</div>
    <nav [attr.aria-label]="linkSection.name + ' Table of Contents'">
      <a [href]="_rootUrl + '#' + link.id"
        *ngFor="let link of linkSection.links; let i = index"
        class="toc-level-{{link.type}} toc-link"
        [class.toc-active]="link.active">
        {{link.name}}
      </a>
    </nav>
  </div>