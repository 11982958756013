<div class="login-form-flex">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Login</mat-card-title>
        </mat-card-header>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <mat-card-content>
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput placeholder="Ex: crazy_dev89" formControlName="username">
                </mat-form-field>
                <br>
                <mat-form-field>
                    <mat-label>Enter your password</mat-label>
                    <input matInput formControlName="password" [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions>
                <div class="button-flex-container">
                    <button mat-raised-button color="primary">Login <mat-icon>login</mat-icon></button>
                </div>
            </mat-card-actions>
        </form>
    </mat-card>
</div>